@import "../../football/styles/homepage";

body {
	.homepage .container.media_partners {
		@import "layout/homepage/media-partners";
	}

	.modal.video .modal-dialog {
		max-width: 600px;

		.modal-body {
			text-align: center;
		}
	}
}